import React from "react";
import { Link } from "gatsby";

const OfficeBtn = () => {
  return (
    <div className="office">
      <div className="office__item">
        <Link className="office__btn" to="/sales">
          Отдел продаж
        </Link>
      </div>
      <div className="office__item">
        <Link className="office__btn" to="/service#service_phone">
          Сервисный отдел
        </Link>
      </div>
      <div className="office__item">
        <Link className="office__btn" to="/hr">
          Отдел кадров
        </Link>
      </div>
      <div className="office__item">
        <Link className="office__btn" to="/logistics">
          Отдел логистики и закупок
        </Link>
      </div>
    </div>
  );
};

export default OfficeBtn;
