import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import OfficeBtn from "../components/officeBtn";

export default class Contacts extends React.Component {

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;

    s.src =
      "https://api-maps.yandex.ru/services/constructor/1.0/js/?sid=pumPbE_uvaCysvFDXMZvn16l9-ir4dAI&amp;width=100%&amp;height=450";
    this.instance.appendChild(s);
    const h = document.getElementById("yandexmap");
    h.parentNode.insertBefore(s, h);
  }

  render() {

    return (
      <Layout>
        <div ref={(el) => (this.instance = el)} />

        <div className="container">
          <h1 className="text-center mt-60 mb-60">КОНТАКТНАЯ ИНФОРМАЦИЯ</h1>
          <OfficeBtn />
          <div className="main_contacts mt-60">
            <div className="main_contacts__body">
              <div className="main_contacts__description">
                <div className="main_contacts__title">Адрес:</div>
                <div className="main_contacts__contact">
                  188514, Россия, <br />
                  Ленинградская область, д.Глядино
                </div>
              </div>
              <div className="main_contacts__description">
                <div className="main_contacts__title"> Почтовый адрес:</div>
                <div className="main_contacts__contact">
                  188514, ОПС Ропша, <br />
                  Ленинградская область, д.Глядино, <br />
                  19 км. Красносельского шоссе
                </div>
              </div>
              <div className="main_contacts__description">
                <div className="main_contacts__title">Тел:</div>
                <div className="main_contacts__contact">
                  <ul>
                    <li>+7 (812) 640-30-30 (многоканальный) </li>
                    <li>
                      +7 (800) 333-40-30 <br />
                      (бесплатный для всех регионов России)
                    </li>
                    <li className="main_contacts__bold">Офис-менеджер</li>
                    <li>
                      +7 (812) 640-30-30 доб 704 <br />
                      моб. +7-931-104-67-62
                    </li>
                    <li className="main_contacts__bold">
                      Заместитель генерального директора
                    </li>
                    <li className="main_contacts__bold">
                      Фроленкова Елена Павловна
                    </li>
                    <li>моб. +7-911-978-62-48</li>
                  </ul>
                </div>
              </div>
              <div className="main_contacts__description">
                <div className="main_contacts__title"> Факс:</div>
                <div className="main_contacts__contact">+7 (812) 640-30-30</div>
              </div>
              <div className="main_contacts__description">
                <div className="main_contacts__title"> E-mail:</div>
                <div className="main_contacts__contact">office@dendor.ru</div>
              </div>
              <div className="main_contacts__description">
                <div className="main_contacts__title"> Часы работы офиса:</div>
                <div className="main_contacts__contact">
                  ПН-ПТ: 09:00 — 18:00; СБ-ВС — выходные
                </div>
              </div>
              <div className="main_contacts__description">
                <div className="main_contacts__title"> Часы работы склада:</div>
                <div className="main_contacts__contact">
                  ПН-ПТ: 09:00 — 17:00; СБ-ВС — выходные
                </div>
              </div>
            </div>
            <div className="main_contacts__img">
              <StaticImage
                className="main_contacts__picture"
                alt="Контакты компании Dendor"
                src="../images/dendor-screen-2020.jpg"
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="main_contacts mt-60">
            <div className="main_contacts__img">
              <StaticImage
                className="main_contacts__picture"
                alt="Контакты компании Dendor"
                src="../images/new_map.jpg"
                placeholder="#fff"
              />
            </div>
            <div className="main_contacts__body">
              <div className="coords">
                <div className="coords__title text-center">КООРДИНАТЫ:</div>
                <div className="coords__desc">
                  GPS: Широта: 59°43′1.93″N (59.717204). Долгота: 29°47′23.69″E
                  (29.789915)
                </div>
              </div>
              <div className="coords">
                <div className="coords__title text-center">
                  РЕКОМЕНДУЕМЫЙ МАРШРУТ:
                </div>
                <div className="coords__desc">
                  <ul>
                    <li>
                      — По КАД до транспортной развязки на Ропшинское шоссе (КАД
                      93 км внутр.,КАД 47 км внешн.);
                    </li>
                    <li>
                      — +Далее 11,5 км на юг по Ропшинскому шоссе до поворота на
                      Анташи (будет большой синий указатель);
                    </li>
                    <li> — Поворот направо по указателю на Анташи</li>
                    <li> — 3,5 км от поворота и Вы у нас.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-60 yandexmap">
          <div id="yandexmap"></div>
        </div>
      </Layout>
    );
  }
}
